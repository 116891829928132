import { SVGProps } from 'react';

import { color } from '../../styles/color';

function ClockFill({
  fill = color.neutral500,
  width = 24,
  height = 24,
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2330_9729)">
        <path
          d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C21.9971 9.34872 20.9426 6.80684 19.0679 4.9321C17.1932 3.05736 14.6513 2.00287 12 2V2ZM12.8333 11.8992C12.8334 12.0404 12.7976 12.1794 12.7292 12.303C12.6609 12.4266 12.5622 12.5308 12.4425 12.6058L9.2425 14.6058C9.14948 14.6639 9.04593 14.7032 8.93775 14.7213C8.82958 14.7393 8.7189 14.7359 8.61203 14.7113C8.50517 14.6866 8.40421 14.6411 8.31493 14.5774C8.22564 14.5137 8.14978 14.433 8.09167 14.34C8.03356 14.247 7.99434 14.1434 7.97625 14.0352C7.95816 13.9271 7.96156 13.8164 7.98624 13.7095C8.01093 13.6027 8.05642 13.5017 8.12012 13.4124C8.18382 13.3231 8.26448 13.2473 8.3575 13.1892L11.1667 11.4375V7.83333C11.1667 7.61232 11.2545 7.40036 11.4107 7.24408C11.567 7.0878 11.779 7 12 7C12.221 7 12.433 7.0878 12.5893 7.24408C12.7455 7.40036 12.8333 7.61232 12.8333 7.83333V11.8992Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2330_9729">
          <rect
            width={width}
            height={height}
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ClockFill;
